<template>
    <VMenu
        ref="menu"
        v-model:shown="isShown"
        theme="sdMenu"
        v-bind="$attrs"
        class="inline-block"
        :auto-hide="!isSheet"
    >
        <template #default="slotProps">
            <!-- @slot slot for the trigger element -->
            <slot v-bind="slotProps" />
        </template>

        <template #popper="{ shown, hide: _hide }">
            <div
                v-if="!isSheet"
                class="bg-white rounded shadow-md"
            >
                <!--
                    @slot custom menu that will also be rendered in the bottom sheet on smaller screens
                    @binding {boolean} is-sheet indicates whether the menu is a bottom sheet or not
                    @binding {boolean} is-shown indicates whether the menu is shown
                    @binding {Callable} hide function to close the menu
                -->
                <slot
                    name="menu"
                    :is-sheet="false"
                    :is-shown="shown"
                    :hide="_hide"
                >
                    <!-- this template shares similarities with the BottomSheetMenu component -->
                    <ul class="flex flex-col divide-y divide-grey-200 light-theme">
                        <li
                            v-for="({ onclick, ...option }, i) in options"
                            :key="option.id ?? i"
                            class="first:rounded-t last:rounded-b"
                            :data-testid="option.id ? `menu-item-${option.id}` : undefined"
                        >
                            <!-- @slot custom menu item that applies to all options -->
                            <slot
                                name="menu-item"
                                :option="option"
                            >
                                <!-- @slot custom menu item that only applies to a specific option -->
                                <slot
                                    :name="`menu-item-${option.id ?? i}`"
                                    :option="option"
                                >
                                    <component
                                        :is="option.is ?? 'menu-item'"
                                        v-bind="option"
                                        @click="onclick ? onclick($event) : $emit('click:option', option, $event)"
                                    />
                                </slot>
                            </slot>
                        </li>
                    </ul>
                </slot>
            </div>
        </template>
    </VMenu>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import type { PropType } from 'vue';
import MenuItem from '@/components/MenuItem';
import BottomSheetMenu from '@/components/BottomSheetMenu';
import { useScreenSize } from '@/composables/screenSize';
import type { MenuOption } from './Menu';

/**
 * A menu component that can be used to display a list of options.
 * It is powered by floating-vue and will appear below the trigger element, or somewhere else if there is
 * no space below.
 * The Menu component will be wrapped around the trigger element. Please be aware that on smaller screens,
 * the behavior of VMenu and the normal menu is not utilized. Instead, when the button is clicked,
 * the BottomSheetMenu will be displayed
 *
 * You can pass any floating-vue prop and it will be forwarded to the underlying floating-vue component.
 * [Check the docs](https://floating-vue.starpad.dev/api/#component-props) for more details.
 *
 * Provided props allow further customization of the displayed menu. All slot content will also be forwarded to the BottomSheetMenu.
 * By providing an `id` to an option, you are able to overwrite a specific option through a slot based on its id `menu-item-<id>`
 */
export default defineComponent({
    name: 'Menu',
    components: {
        MenuItem,
    },
    inheritAttrs: false,
    props: {
        /**
         * Array of menu options.
         */
        options: {
            type: Array as PropType<MenuOption[]>,
            default: () => [],
        },
        /**
         * Title of the menu
         */
        title: {
            type: String,
            default: null,
        },
    },
    emits: ['click:option'],
    setup() {
        return {
            isShown: ref(false),
            isSheet: useScreenSize().maxSM,
        };
    },
    computed: {
        showBottomSheet() {
            return this.isShown && this.isSheet;
        },
    },
    watch: {
        showBottomSheet(val) {
            if (!val) {
                /** @todo customize close method to avoid closing all modals at once */
                this.$modal.close();
                return;
            }

            this.$modal.open(
                BottomSheetMenu,
                {
                    title: this.title,
                    options: this.options,
                },
                {
                    close: () => {
                        // if a screen size change occurred we want to keep the menu open because it was not intentionally closed
                        this.isShown = !this.isSheet;
                    },
                    'click:option': (...args: unknown[]) => this.$emit('click:option', ...args),
                },
                this.$slots,
            );
        },
    },
    methods: {
        show() {
            this.isShown = true;
        },
        hide() {
            this.isShown = false;
        },
    },
});
</script>
